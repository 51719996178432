import React from 'react';
import { Route, Router  } from 'react-router';
import { pagesRouter } from './router/pageRouter';
import { history } from './common/utils';
import './App.css';
import './styles/common.css';

type Props = {};
type State = {};

export class App extends React.Component<Props, State> {
  renderRoutePages = () => {
    return pagesRouter.map((item) => {
        return (
            <Route key={item.path} exact path={item.path} component={() => (<item.page pageId={item.id} />)}/>
        )
    })
  }

  render() {
    return(
      <div className="App">
        <Router history={history}>
            <div>
              {this.renderRoutePages()}
            </div>
          </Router>
      </div>
    )
  }
}

export default App;