import React from 'react';
import { RecordSideMenu } from './components/recordSideMenu';
import { Service } from '../common/restful/Service';
import { RecordTableWithTable } from './components/recordTable';
import '../styles/record-entry.css';
import { history } from '../common/utils';

type Props = {};
type State = {
    tableNumber: string,
    customTableList: any[],
};

export class Page extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            tableNumber: '',
            customTableList: [],
        };
    };

    componentDidMount() {
        window.localStorage.setItem('menuKey', '');
        this.getCustomTableList();
    };

    async getCustomTableList() {
        let customTableList: any = [];
        try {
            const response: any = await Service.getInstance().getCustomTableList();
            customTableList = response.data.data;
        } catch (e) {
            console.error(e);
        } finally {
            this.setState({
                customTableList,
            });
        }
    };

    onMenuItemSelected = (key: string) => {
        const { tableNumber } = this.state;
        if (key !== tableNumber) {
            this.setState({tableNumber: key});
            window.localStorage.setItem('menuKey', key);
        }
    };

    render() {
        const { customTableList, tableNumber } = this.state;
        let isFromReport: boolean = !!history.location.state;

        return (
            <div className='record-entry-container'>
                <div className={isFromReport ? 'hidden-left-container' : 'left-container'}>
                    <RecordSideMenu customTableData={customTableList} onMenuItemSelected={this.onMenuItemSelected} />
                </div>
                <div className={isFromReport ? 'full-right-container' : 'right-container'}>
                    <RecordTableWithTable menuKey={tableNumber} />
                </div>
            </div>
        )
    }
}