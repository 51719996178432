const isDev = process.env.NODE_ENV === 'development';

// 开发环境 restful server 配置
const devServers = {
    restFulAPIPath: 'http://localhost:11300',
};

// 生产环境 restful server 配置
const prodServers = {
    restFulAPIPath: window.location.protocol + '//' + window.location.host,
};

export const restFulAPIPath = isDev ? devServers.restFulAPIPath : prodServers.restFulAPIPath;
