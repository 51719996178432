import React from 'react';
import { Table, Pagination, Row, Col } from 'antd';
import { Service } from '../common/restful/Service';
import { history } from '../common/utils';
import moment from 'moment';

const dateFormat: string = 'YYYY/MM/DD hh:mm:ss';
const eventStateMap: any = {
    'Trigger.false': '触发/未确认',
    'Trigger.true': '触发/已确认',
    'Remove.false': '恢复/未确认',
    'Remove.true': '恢复/已确认',
};
const eventSeverityMap: any = {
    Warning: '警告',
    Minor: '次要',
    Major: '主要',
    Critical: '严重',
};

type Props = {};
type State = {
    eventGroupId: string,
    serverUrl: string,
    dataSource: any,
    pagination: any,
};

export class Page extends React.Component<Props, State> {
    columns: any = [{
        title: '优先级',
        align: 'center',
        ellipsis: true,
        width: 100,
        render: (record: any) => {
            return eventSeverityMap[record.Severity];
        },
    },  {
        title: '事件编号',
        align: 'center',
        ellipsis: true,
        dataIndex: 'EventId',
        width: 200,
    },  {
        title: '名称',
        dataIndex: 'Name',
        align: 'center',
        ellipsis: true,
        width: 200,
    }, {
        title: '触发时间',
        align: 'center',
        ellipsis: true,
        width: 200,
        render: (record: any) => {
            return moment(record.TriggeredTime).format(dateFormat);
        },
    }, {
        title: '事件文本',
        dataIndex: 'EventText',
        align: 'center',
        ellipsis: true,
        width: 200,
    }, {
        title: '状态',
        align: 'center',
        ellipsis: true,
        width: 150,
        render: (record: any) => {
            return eventStateMap[`${record.Status}.${record.IsAck}`];
        },
    }, {
        title: '告警组',
        dataIndex: 'GroupName',
        align: 'center',
        ellipsis: true,
        width: 150,
    }, {
        title: '恢复时间',
        align: 'center',
        ellipsis: true,
        width: 200,
        render: (record: any) => {
            return moment(record.RemovedTime).format(dateFormat);
        },
    }, {
        title: '确认者',
        dataIndex: 'AckByUserName',
        align: 'center',
        ellipsis: true,
        width: 100,
    }, {
        title: '确认时间',
        align: 'center',
        ellipsis: true,
        width: 200,
        render: (record: any) => {
            return moment(record.AckTime).format(dateFormat);
        },
    }, {
        title: '持续时间',
        align: 'center',
        ellipsis: true,
        width: 150,
        render: (record: any) => {
            return this.formatDuration(record.Duration);
        },
    }, {
        title: '累计时间',
        align: 'center',
        ellipsis: true,
        width: 150,
        render: (record: any) => {
            return this.formatDuration(record.CumulativeDuration);
        },
    }, {
        title: '发生次数',
        dataIndex: 'CumulativeFrequency',
        align: 'center',
        ellipsis: true,
        width: 100,
    }];
    location: any = history.location;
    lineName: string;

    constructor(props: Props) {
        super(props);

        this.lineName = this.location?.state?.lineName;
        this.state = {
            eventGroupId: '',
            serverUrl: '',
            dataSource: [],
            pagination: {
                pageSize: 100,
                dataTotal: 0,
                currentPage: 1,
                pageStatus: {
                    TriggerAck: 0,
                    TriggerUnack: 0,
                    RemoveAck: 0,
                    RemoveUnack: 0,
                },
            }
        };
    }

    async componentDidMount() {
        let res: any = await this.getEventGroupIdAndServerUrl();
        let eventGroupId: string = res.eventGroupId
        let serverUrl: string = res.serverUrl

        this.setState({ eventGroupId,serverUrl }, () => {
            this.getAlarmData();
        })
    }

    formatDuration = (time: number) => {
        let timeData = time / 1e6;
        let timeFormat = '';
        const days = Math.floor(moment.duration(timeData).asDays());
        if (days) {
            timeFormat += `${days}天`;
        }
        const hours = moment.duration(timeData).hours();
        if (hours) {
            timeFormat += `${hours}时`;
        }
        const minutes = moment.duration(timeData).minutes();
        if (minutes) {
            timeFormat += `${minutes}分`;
        }
        timeData = timeData / 1e3 - days * 86400 - hours * 3600 - minutes * 60;
        if (timeData > 0) {
            const seconds = timeData.toFixed(1);
            timeFormat += `${seconds}秒`;
        }
        return timeFormat;
    };

    getEventGroupIdAndServerUrl = async() => {
        let response: any;
        let eventGroupId: string = '';
        let serverUrl: string = '';
        try {
            response = await Service.getInstance().getVariablesAndEventId({lineName: this.lineName});
            eventGroupId = response?.data?.data?.eventGroupId ?? '';
            serverUrl = response?.data?.data?.serverUrl ?? '';
        } catch (error) {
            console.log(error);
        } finally {
            return {
                eventGroupId: eventGroupId,
                serverUrl: serverUrl
            };
        }
    }

    getAlarmData = async () => {
        const { pagination, eventGroupId, serverUrl } = this.state;
        const startTime: string = this.location?.state?.startTime;
        const endTime: string = this.location?.state?.endTime;
        let response: any;
        const params: any = {
            Type: "Alarm",
            Status:[],
            Severity: [],
            GroupId: [eventGroupId],
            TriggeredStartTime: moment(startTime).format(),
            TriggeredEndTime: moment(endTime).format(),
            CurrentPage: pagination.currentPage,
            PageSize: pagination.pageSize,
        }
        try {
            response = await Service.getInstance().getAlarmData(params, serverUrl);
            let data: any = response.data;
            this.setState({ 
                dataSource: data.Data,
                pagination: {
                    ...pagination,
                    dataTotal: data.Count,
                    pageStatus: {
                        TriggerAck: data.TriggerAckCount,
                        TriggerUnack: data.TriggerUnackCount,
                        RemoveAck: data.RemoveAckCount,
                        RemoveUnack: data.RemoveUnackCount,
                    },
                },
            });
        } catch (error) {
            console.error(error);
        }
    }

    onPageChange = (page: number) => {
        const { pagination } = this.state;

        this.setState({ 
            pagination: {
                ...pagination,
                currentPage: page,
            },
        }, () => {
            this.getAlarmData();
        });
    }

    render() {
        const { dataSource, pagination } = this.state;
        const title = this.lineName ? `告警 - ${this.lineName}` : '告警'
        
        return (
            <div style={{padding: 30}}>
                <h2>{title}</h2>
                <Table
                    dataSource={dataSource}
                    columns={this.columns}
                    pagination={false}
                    bordered={true}
                    rowKey={(record) => record.ID}
                    scroll={{x: '100%', y: 'calc(100vh - 100px)'}}
                />
                <Row style={{paddingTop: 20}}>
                    <Col span={18}>
                        <div className='flex items-center'>
                            <span style={{ marginRight: 20 }}>{`总计：${pagination.dataTotal}`}</span>
                            <span style={{ marginRight: 20 }}>{`触发/未确认：${pagination.pageStatus.TriggerUnack}`}</span>
                            <span style={{ marginRight: 20 }}>{`触发/已确认：${pagination.pageStatus.TriggerAck}`}</span>
                            <span style={{ marginRight: 20 }}>{`恢复/未确认：${pagination.pageStatus.RemoveUnack}`}</span>
                            <span style={{ marginRight: 20 }}>{`恢复/已确认：${pagination.pageStatus.RemoveAck}`}</span>
                        </div>
                    </Col>
                    <Col span={6} className='flex-end'>
                        <Pagination
                            simple
                            defaultCurrent={1}
                            current={pagination.currentPage}
                            pageSize={pagination.pageSize}
                            total={pagination.dataTotal}
                            onChange={this.onPageChange}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}