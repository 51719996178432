import React from 'react';
import { Button, Input, message, Upload, Modal } from 'antd';
import { CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import { Service } from '../../common/restful/Service';
import '../../styles/record-entry.css';

type Props = {
    visible: boolean,
    onFinish: (isRefresh: boolean) => void,
};
type State = {
    uploading: boolean,
    importFile: any,
};

export class ImportModal extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            uploading: false,
            importFile: null,
        };
    };

    uploadFile = async () => {
        this.setState({ uploading: true });
        const { importFile } = this.state;
        let menuKey: any = window.localStorage.getItem('menuKey');
        const data = new FormData();
        data.append('file', importFile);
        data.append('id', menuKey);
        try {
            const response: any = await Service.getInstance().importCustomRecord(data);
            if (response.data.code === 200) {
                message.success('导入成功');
                this.setState({importFile: null});
                this.props.onFinish(true);
            } else if (response.data.code === 2190) {
                message.info(response.data.msg);
            } else {
                message.error("导入失败");
            }
            this.setState({ uploading: false });
        } catch (e) {
            console.error(e);
            message.error('导入失败');
            this.setState({ uploading: false });
        }
    }

    onCancel = () => {
        this.setState({importFile: null});
        this.props.onFinish(false);
    }

    templateDownload = async () => {
        let menuKey: any = window.localStorage.getItem('menuKey');
        const response: boolean = await Service.getInstance().templateDownload(menuKey);
        if (!response) {
            message.error('下载模板失败');
        }
    }

    renderImport = () => {
        const { uploading, importFile } = this.state;
        return (
            <div className="flex items-center justify-center">
                <div style={{ padding: 0, flexGrow: 1 }}>
                    <Input
                        readOnly
                        value={importFile?.name}
                        suffix={
                            importFile ? (
                                <CloseOutlined
                                    onClick={() => {
                                        this.setState({importFile: null});
                                    }}
                                />
                            ) : <></>
                        }
                    />
                </div>
                <Upload
                    accept=".xlsx,.xls"
                    showUploadList={false}
                    fileList={importFile ? [importFile] : []}
                    customRequest={this.uploadFile}
                    beforeUpload={(file: any, _fileList: any) => {
                        this.setState({importFile: file});
                        return false;
                    }}
                    disabled={uploading}
                >
                    <Button
                        type="primary"
                        style={{ marginLeft: 5 }}
                    >
                        选择文件
                    </Button>
                </Upload>
            </div>
        )
    }

    renderTip = () => {
        return (
            <div
                className='template-container'>
                <div className='template-tip'>
                    如需快速添加数据，请按如下模板编写文件
                </div>
                <div
                    aria-hidden
                    className="download-container"
                    onClick={() => {
                        this.templateDownload()
                    }}
                >
                    <DownloadOutlined className='flex items-center' />
                    <span>下载导入模版</span>
                </div>
            </div>
        )
    }

    render(): any {
        const { visible } = this.props;
        const { uploading, importFile } = this.state;
        return (
            <Modal
                visible={visible}
                title='表格导入'
                closable={false}
                maskClosable={false}
                width={400}
                confirmLoading={uploading}
                onOk={this.uploadFile}
                onCancel={this.onCancel}
                okText='导入'
                okButtonProps={{disabled: importFile === null}}
            >
                { this.renderImport() }
                { this.renderTip() }
            </Modal>
        )
    }
}