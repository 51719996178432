import * as page1 from '../pages/traceReport';
import * as page2 from '../pages/recordEntry';
import * as page3 from '../pages/customTable';
import * as page4 from '../pages/lineChart';
import * as page5 from '../pages/alarm';

export const pagesRouter: any[] = [
    {path: '/', page: page2.Page, id: 'page-recordEntry'},
    {path: '/traceReport', page: page1.Page, id: 'page-traceReport'},
    {path: '/recordEntry', page: page2.Page, id: 'page-recordEntry'},
    {path: '/customTable', page: page3.Page, id: 'page-customTable'},
    {path: '/lineChart', page: page4.Page, id: 'page-lineChart'},
    {path: '/alarm', page: page5.Page, id: 'page-alarm'},
];