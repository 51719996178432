import axios from 'axios';
import * as qs from 'qs';

export const Request = (req: {
        url: string,
        method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | string,
        params?: object | string,
        data?: object | string,
        responseType?: string,
        timeout?: number,
        headers?: object }) => {
    let headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer',
    };
    if (req.headers) {
        headers = {
            ...headers,
            ...req.headers,
        };
    }
    return new Promise((resolve, reject) => {
        axios({
            url: req.url,
            method: req.method || 'GET',
            params: req.params || {},
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
            data: req.data || {},
            responseType: req.responseType,
            headers,
            timeout: req.timeout,
        }).then(resolve).catch((error) => {
            reject(error);
        });
    });
};
