import React from 'react';
import * as echarts from 'echarts';
import moment from 'moment';
import { Select } from 'antd';
import { Service } from '../common/restful/Service';
import { history } from '../common/utils';

const { Option } = Select;
const dateFormat: string = 'YYYY/MM/DD__hh:mm:ss';

type EChartsOption = echarts.EChartsOption;
type Props = {
    variables: any,
};
type State = {
    variables: any,
    serverUrl: string,
    selectedKeys: any,
};

export class Page extends React.Component<Props, State> {
    eChartsRef: any = React.createRef();
    myChart: any;
    location: any = history.location;
    lineName: string;
    option: EChartsOption = {
        title: {
            text: '',
        },
        tooltip: {
            trigger: 'axis',
            textStyle: {
                fontSize: 20,
            },
        },
        legend: {
            data: [],
        },
        grid: {
            left: 100,
            right: 30,
            top: '10%',
            bottom: '10%',
        },
        xAxis: {
            type: 'time',
            splitLine: {
                show: false
            },
            axisLabel: {
                formatter: (item: any) => {
                    let text = moment(item).format(dateFormat);
                    text = text.split('__').join('\n')
                    return text;
                },
            }
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: true
            },
            max: function(value){
                return (value.max + Math.abs(value.max * 0.2)).toFixed(3)
            },
            min: function(value){
                return (value.min - Math.abs(value.min * 0.2)).toFixed(3) 
            }
        },
        series: []
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            variables: [],
            serverUrl: '',
            selectedKeys: [],
        };
        this.lineName = this.location?.state?.lineName;
    }

    async componentDidMount() {
        this.myChart = echarts.init(this.eChartsRef.current);
        let res: any = await this.getVariablesAndServerUrl();
        let variables: any = res.variables
        let serverUrl: string = res.serverUrl
        this.setState({ variables, serverUrl }, () => {
            let selectedKeys: any = this.getDefaultValue();
            this.getEchartData(selectedKeys);
        });
    }

    componentWillUnmount() {
        this.myChart.dispose();
    }

    getDefaultValue = () => {
        const { variables } = this.state;
        let selectedKeys: any = [];
        // 默认展示前三个变量
        variables?.forEach((item: any, index: number) => {
            if (index < 3) {
                selectedKeys.push(item.id);
            }
        });
        this.setState({ selectedKeys });
        return selectedKeys;
    }

    getParmas = (selectedKeys: any) => {
        const startTime: string = this.location?.state?.startTime;
        const endTime: string = this.location?.state?.endTime;
        let params: any = {
            DataCount: 500,
            StartTime: moment(startTime).format(),
            EndTime: moment(endTime).format(),
            variables: [],
        };
        selectedKeys?.forEach((id: any) => {
            let variable: any = {
                id,
                methods: ["basicAverage"],
            };
            params.variables.push(variable)
        });

        return params;
    }

    getVariablesAndServerUrl = async() => {
        let response: any;
        let variables: any = [];
        let serverUrl: string = '';
        try {
            response = await Service.getInstance().getVariablesAndEventId({lineName: this.lineName});
            variables = response?.data?.data?.variables ?? [];
            serverUrl = response?.data?.data?.serverUrl ?? '';
            console.log('sss',serverUrl)
        } catch (error) {
            console.log(error);
        } finally {
            return {variables: variables, serverUrl: serverUrl};
        }
    }

    getEchartData = async (selectedKeys: any) => {
        const { variables, serverUrl } = this.state;
        console.log(serverUrl)
        let legendData: any = [];
        let response: any;
        try {
            let params: any = this.getParmas(selectedKeys);
            response = await Service.getInstance().getEchartData(params, serverUrl);
        } catch (error) {
            console.error(error);
        }
        let seriesData: any = {};
        // 转换series
        response?.data?.forEach((item: any) => {
            const keys = Object.keys(item);
            const seriesKeys = Object.keys(seriesData);
            keys.forEach((dataId) => {
                if (dataId === 'ts') {
                    return;
                }
                let data: any;
                // 若id已存在seriesData中则只更新其中的data,否则在seriesData新增一个线的对象再更新其data
                if (!seriesKeys.includes(dataId)) {
                    // 根据变量id获取展示名称
                    let item: any = variables.find((item: any) => {
                        return item.id === dataId;
                    })
                    seriesData[dataId] = {
                        name: item?.displayName ?? '',
                        type: 'line',
                        showSymbol: true,
                        data: [],
                    };
                    legendData.push(item?.displayName ?? '');
                }
                data = seriesData[dataId].data;
                const time: any = parseInt(moment(item['ts']).format('x'), 10);
                data.push([time, item[dataId].toFixed(3)]);
            });
        });
        this.option = {
            ...this.option,
            legend: {
                data: legendData,
            },
            series: Object.values(seriesData),
        };
        this.myChart.setOption(this.option, true);
    }

    onVariableChange = (selectedKeys: any, options: any) => {
        this.setState({ selectedKeys });
        this.getEchartData(selectedKeys);
    }

    renderSelect() {
        const { variables, selectedKeys } = this.state;

        return (
            <>
                <label style={{marginRight: 20}}>展示变量:</label>
                <Select
                    mode={'multiple'}
                    showSearch
                    placeholder="选择展示变量"
                    optionFilterProp="children"
                    onChange={this.onVariableChange}
                    filterOption={(input: string, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{width: 300}}
                    value={selectedKeys}
                    maxTagCount={3}
                >
                    {
                        variables?.map((item: any) => {
                            return <Option key={item.id} value={item.id}>{item.displayName}</Option>
                        })
                    }
                </Select>
            </>
        )
    }

    render() {
        const title = this.lineName ? `趋势图 - ${this.lineName}` : '趋势图'

        return (
            <div className='flex-direction-column items-center' style={{ width: '100%', height: '100vh', padding: 30 }}>
                <h2>{title}</h2>
                <div style={{marginTop: 20}}>{ this.renderSelect() }</div>
                <div ref={this.eChartsRef} style={{ width: '80%', height: '80%', marginTop: 60 }} />
            </div>
        );
    }
}