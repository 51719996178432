import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Card, Popconfirm, message } from 'antd';
import { Service } from '../../common/restful/Service';
import { EditRecordModalWithConnectForm } from './editRecordModal';
import { Table, Search, withTable, useTable } from 'table-render';
import { ImportModal } from './importModal';
import { formatSingleColumn, history } from '../../common/utils';

type Props = {
    menuKey: string,
};

const defaultSearchShema = {
    type: "object",
    properties: {},
    column: 3,
    labelWidth: 80,
    displayType: "row"
};

const dateTimeFormatList = ['date', 'dateTime'];
const ignoreFormFields: any = ['current', 'pageSize', 'stay'];

const RecordTable = (props: Props) => {
    const { menuKey } = props;
    const { refresh, form, tableState } = useTable();

    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [currentItem, setCurrentItem] = useState<any>(null);
    const [tableNumber, setTableNumber] = useState<string>('');
    const [customTableData, setCustomTableData] = useState<any>(null);
    const [columns, setColumns] = useState<any[]>([]);
    const [showImportModal, setShowImportModal] = useState<boolean>(false);
    const [exporting, setExporting] = useState<boolean>(false);

    const location: any = history.location;
    const searchValue: any = location?.state?.value;

    useEffect(() => {
        // 第一次加载
        if (tableNumber === "") refresh!(searchValue ?? {});
        // 菜单项切换：重置查询条件
        if (menuKey !== tableNumber) {
            setTableNumber(menuKey);
            form.setValues(searchValue ?? {});
            form.init(searchValue ?? {});
        }
    }, [menuKey]);

    const formatColumns = (columnsData: any[]) => {
        const columns: any[] = [];
        columnsData.forEach((item) => {
            let column = formatSingleColumn(item);
            if (!!column) {
                columns.push(column);
            }
        });
        let actionColumn = {
            key: 'action',
            title: '操作',
            align: 'center',
            fixed: 'right',
            width: 120,
            render: (record: any) => {
                return (
                    <div>
                        <Button type="primary" size='small' onClick={()=> {
                                onEditClick(record)
                            }}
                        >
                            编辑
                        </Button>
                    </div>
                )
            }
        };
        columns.push(actionColumn);
        return columns;
    };

    const onSelectChange = (selectedRowKeys: any[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const onConfirmDelete = async () => {
        try {
            const response: any = await Service.getInstance().deleteCustomRecord({ recordIds: selectedRowKeys });
            if (response.data.code === 200) {
                message.success('删除成功');
                onEditFinish(false, true);
            } else {
                message.error('删除失败');
            }
        } catch (e) {
            console.error(e);
            message.error('删除失败');
        }
    };

    const onEditFinish = (isModalVisible: boolean, isRefresh: boolean) => {
        setIsModalVisible(isModalVisible);
        setCurrentItem(null);
        if (isRefresh) {
            onRefresh();
        }
    };

    const onNewClick = () => {
        setIsModalVisible(true);
    };

    const onEditClick = (record: any) => {
        setIsModalVisible(true);
        setCurrentItem(record);
    };

    const formatSearchFormSchema = () => {
        let formSchema: any = null;
        let searchFields = '';
        if (!!customTableData && !!customTableData.customTableDefinition) {
            formSchema = JSON.parse(customTableData.customTableDefinition.customizedFields);
            searchFields = customTableData.customTableDefinition.selectFields;
        }
        if (!formSchema || !searchFields) {
            return defaultSearchShema;
        }

        let searchKeys: string[] = searchFields.split(',');
        let searchProperties: any = {};
        let searchSchema: any = {};
        searchProperties = formatSingleSchema(formSchema.properties, searchKeys)
        searchSchema = {
            ...formSchema,
            properties: searchProperties
        };
        return searchSchema;
    };

    const formatSingleSchema = (properties: any, searchKeys: any[]) => {
        let searchProperties: any = {};
        
        for (const fieldName of searchKeys) {
            let property = properties[fieldName];
            if (!property) {
                for (const key of Object.keys(properties)) {
                    let parentProperty: any = properties[key];
                    if (!!parentProperty && !!parentProperty.properties) {
                        let result = formatSingleSchema(parentProperty.properties, searchKeys);
                        if (!!result && Object.keys(result).length > 0) {
                            searchProperties = {
                                ...searchProperties,
                                ...result
                            };
                            break
                        }
                        
                    }
                }
            } else {
                // 查询字段是日期或时间日期格式时，将其转成日期或日期时间范围格式
                if (dateTimeFormatList.includes(property.format)) {
                    property = {
                        ...property,
                        type: 'range',
                        props: {
                            placeholder: ['开始时间', '结束时间'],
                        }
                    };
                }
                searchProperties = {
                    ...searchProperties,
                    [fieldName]: property
                };
            }
        }
        return searchProperties;
    }

    const getCustomTableData = async (data: any) => {
        let customTableData: any;
        let columns: any[] = [];
        try {
            const response: any = await Service.getInstance().getCustomTableData(data);
            customTableData = response.data.data;
            if (!!customTableData) {
                columns = formatColumns(customTableData.columns ?? []);
            }
            setCustomTableData(customTableData);
            setColumns(columns);
        } catch (e) {
            console.error(e);
            return customTableData;
        }
        return customTableData;
    };

    const getSearchParams = (searchData: any) => {
        let dataList: any[] = [];
        if (!!searchData) {
            Object.keys(searchData).forEach((key) => {
                if (ignoreFormFields.indexOf(key) === -1) {
                    let field = {
                        fieldName: key,
                        fieldValue: searchData[key]?.toString(),
                    };
                    dataList.push(field);
                }
            });
        }
        return dataList;
    };

    const searchApi = async (searchData: any) => {
        let menuKey: any = window.localStorage.getItem('menuKey');
        let data: any;
        let dataList: any[] = getSearchParams(searchData);

        if (!!menuKey) {
            let params: any = {
                id: menuKey,
                dataList,
                pageNum: searchData['current'] ?? 1,
                pageSize: searchData['pageSize'] ?? 10,
            };
            data = await getCustomTableData(params);
        }
        return {
            rows: data?.data ?? [],
            total: data?.totalCount ?? 0,
        };
    };

    const onImportFinish = (isRefresh: boolean) => {
        setShowImportModal(false);
        if (isRefresh) {
            onRefresh();
        }
    }

    const onRefresh = () => {
        refresh!();
    }

    const onExport = async () => {
        if (exporting) {
            return;
        }
        setExporting(true);
        let menuKey: any = window.localStorage.getItem('menuKey');
        let data: any = {
            id: menuKey,
            recordIds: selectedRowKeys
        };
        const response: boolean = await Service.getInstance().exportCustomRecord(data);
        if (!response) {
            message.error('导出失败');
        }
        setExporting(false);
    }

    const onPageChange = (current: number, pageSize: number) => {
        // 切换页码后清空已选项
        if (current !== tableState?.pagination.current) {
            setSelectedRowKeys([]);
        }
    }

    const renderOperation = () => {
        return (
            <>
                <Row>
                    <Col span={2} className='flex-start'>
                        <Button type="primary" className='action-button' onClick={onNewClick}>新增</Button>
                    </Col>
                    <Col span={2}>
                        <Popconfirm
                            title="确定要删除已勾选的数据吗?"
                            onConfirm={ onConfirmDelete }
                            okText="确定"
                            cancelText="取消"
                        >
                            <Button type="primary" className='action-button'>删除</Button>
                        </Popconfirm>
                    </Col>
                    <Col span={2} offset={16} className='flex-end'>
                        <Button
                            type="primary"
                            className='action-button'
                            loading={exporting}
                            onClick={onExport}
                        >
                            导出
                        </Button>
                    </Col>
                    <Col span={2} className='flex-end'>
                        <Button
                            type="primary"
                            className='action-button'
                            onClick={()=> {
                                setShowImportModal(true)
                            }}
                        >
                            表格导入
                        </Button>
                    </Col>
                </Row>
            </>
        )
    };

    const rowSelection = {
        selectedRowKeys,
        fixed: true,
        onChange: onSelectChange,
    };
    let searchSchema: any = formatSearchFormSchema();
    const isHiddenSearch = Object.keys(searchSchema.properties).length === 0;
    let customTableDefinition: any = null;
    if (!!customTableData) {
        customTableDefinition = customTableData.customTableDefinition;
    }
    let tableTop: number = Math.ceil(Object.keys(searchSchema.properties).length / 2) * 30 + 300;

    return (
        <div className='flex-direction-column'>
            <Search schema={searchSchema} hidden={isHiddenSearch} api={searchApi} searchOnMount={false} />
            <Card className='record-table-card'>
                {renderOperation()}
                <Table
                    headerTitle='-'
                    style={{padding: 0, paddingTop: 10}}
                    columns={columns}
                    rowSelection={rowSelection}
                    pagination={{
                        showSizeChanger: true,
                        showTotal: ((total: number) => {
                            return `共${total}条`
                        }),
                        onChange: onPageChange
                    }}
                    bordered={true}
                    size='small'
                    rowKey={(record: any) => record.recordId}
                    scroll={{x: '100%', y: `calc(100vh - ${tableTop}px)`}}
                    className='record-table'
                />
            </Card>
            {!!customTableData && isModalVisible && (
                <EditRecordModalWithConnectForm
                    customTableDefinition={customTableDefinition}
                    data={currentItem}
                    onEditFinish={onEditFinish}
                />
            )}
            <ImportModal visible={showImportModal} onFinish={onImportFinish} />
        </div>
    )
}

export const RecordTableWithTable: any = withTable(RecordTable);