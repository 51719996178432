import React from "react";
import { Menu } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import { history } from '../../common/utils';

type Props = {
    customTableData: any[],
    onMenuItemSelected: (key: string) => void,
};
type State = {
    selectedKeys: string[],
};
const { SubMenu } = Menu;

export class RecordSideMenu extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const location: any = history.location;
        const id: string = location?.state?.id;
        this.state = {
            selectedKeys: id ? [id] : [],
        }
    };

    componentDidUpdate() {
        const { selectedKeys } = this.state;
        if (selectedKeys.length === 0) {
            this.getDefaultSelectedKey();
        } else {
            this.props.onMenuItemSelected(selectedKeys[0]);
        }
    };

    getDefaultSelectedKey = () => {
        const { customTableData } = this.props;
        let selectedKey: string = '';
        if (customTableData.length > 0) {
            selectedKey = customTableData[0].id;
        }
        this.setState({ selectedKeys: [selectedKey] });
    }

    handleClick = (event: any) => {
        let selectedKey: string = event.key;
        this.setState({ selectedKeys: [selectedKey] });
        this.props.onMenuItemSelected(selectedKey);
    };

    render() {
        const { customTableData } = this.props;
        const { selectedKeys } = this.state;

        return (
            <Menu
                onClick={this.handleClick}
                selectedKeys={selectedKeys}
                defaultOpenKeys={['sub1']}
                mode="inline"
            >
                <SubMenu key="sub1" icon={<FileOutlined />} title="自定义质检">
                    {
                        customTableData.map((item) => {
                            return (<Menu.Item key={item.id} icon={<FileOutlined />}>{item.tableDisplayName}</Menu.Item>)
                        })
                    }
                </SubMenu>
            </Menu>
        )
    }
}