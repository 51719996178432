import { Request } from './Request';
import { downLoadFile } from '../utils';
import { restFulAPIPath } from './Server';

const Method = {
    CUSTOM_TABLE_LIST: `${restFulAPIPath}/customtabledefinition/get-tablename`,
    CUSTOM_TABLE_DATA: `${restFulAPIPath}/customtablerecordfield/get-data`,
    DELETE_CUSTOM_RECORD: `${restFulAPIPath}/customtablerecordfield/delete-data`,
    CREATE_CUSTOM_RECORD: `${restFulAPIPath}/customtablerecordfield/insert-data`,
    UPDATE_CUSTOM_RECORD: `${restFulAPIPath}/customtablerecordfield/update-data`,
    CUSTOM_RECORD_IMPORT: `${restFulAPIPath}/customtablerecordfield/tablerecordfield-in`,
    CUSTOM_RECORD_EXPORT: `${restFulAPIPath}/customtablerecordfield/tablerecordfield-out`,
    TEMPLATE_DOWNLOAD: `${restFulAPIPath}/customtablerecordfield/template-download`,
    TRACE_REPORT: `${restFulAPIPath}/customtablerecordfield/retrospect-select`,
    TRACE_REPORT_EXPORT: `${restFulAPIPath}/customtablerecordfield/traceReport-out`,
    TABLE_DEFINITION_LIST: `${restFulAPIPath}/customtabledefinition/select-customtable`,
    CREATE_TABLE_DEFINITION: `${restFulAPIPath}/customtabledefinition/insert-customtable`,
    UPDATE_TABLE_DEFINITION: `${restFulAPIPath}/customtabledefinition/update-customtable`,
    DELETE_TABLE_DEFINITION: `${restFulAPIPath}/customtabledefinition/delete-customtable`,
    GET_VARIABLES_EVENT: `${restFulAPIPath}/readjsonfile/data`,
    GET_ECHART_DATA: '/api/data/history',
    GET_ALARM_DATA: '/api/data/event-history',
};

export class Service {
    private static service: Service;

    static getInstance = (): Service => {
        if (Service.service == null) {
            Service.service = new Service();
        }
        return Service.service;
    }

    async getCustomTableList(): Promise<any> {
        return Request({url: Method.CUSTOM_TABLE_LIST, method: 'GET'});
    }

    async getCustomTableData(data: any): Promise<any> {
        return Request({url: Method.CUSTOM_TABLE_DATA, method: 'POST', data});
    }

    async deleteCustomRecord(data: any): Promise<any> {
        return Request({url: Method.DELETE_CUSTOM_RECORD, method: 'POST', data });
    }

    async createCustomRecord(data: any): Promise<any> {
        return Request({url: Method.CREATE_CUSTOM_RECORD, method: 'POST', data });
    }

    async updateCustomRecord(data: any): Promise<any> {
        return Request({url: Method.UPDATE_CUSTOM_RECORD, method: 'POST', data });
    }

    async importCustomRecord(data: any): Promise<any> {
        return Request({url: Method.CUSTOM_RECORD_IMPORT, method: 'POST', data });
    }

    async exportCustomRecord(data: any): Promise<any> {
        return downLoadFile(Method.CUSTOM_RECORD_EXPORT, 'POST', data);
    }

    async templateDownload(params: any): Promise<any> {
        let url: string = `${Method.TEMPLATE_DOWNLOAD}?id=${params}`;
        return downLoadFile(url, 'GET');
    }
    
    async getTraceReport(data: any): Promise<any> {
        return Request({url: Method.TRACE_REPORT, method: 'POST', data });
    }

    async exportTraceReport(data: any): Promise<any> {
        return downLoadFile(Method.TRACE_REPORT_EXPORT, 'POST', data);
    }

    async getTableDefinitionList(data: any): Promise<any> {
        return Request({url: Method.TABLE_DEFINITION_LIST, method: 'POST', data});
    }
    
    async deleteTableDefinition(params: any): Promise<any> {
        return Request({url: Method.DELETE_TABLE_DEFINITION, method: 'GET', params });
    }

    async createTableDefinition(data: any): Promise<any> {
        return Request({url: Method.CREATE_TABLE_DEFINITION, method: 'POST', data });
    }

    async updateTableDefinition(data: any): Promise<any> {
        return Request({url: Method.UPDATE_TABLE_DEFINITION, method: 'POST', data });
    }

    async getVariablesAndEventId(params: any): Promise<any> {
        return Request({url: Method.GET_VARIABLES_EVENT, method: 'GET', params });
    }

    async getEchartData(data: any, serverUrl: string): Promise<any> {
        console.log(data, serverUrl)
        return Request({ url: serverUrl + Method.GET_ECHART_DATA, method: 'POST', data });
    }
    
    async getAlarmData(data: any, serverUrl: string): Promise<any> {
        console.log(data, serverUrl)
        return Request({ url: serverUrl + Method.GET_ALARM_DATA, method: 'POST', data });
    }
}