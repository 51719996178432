import React from 'react';
import { message, Modal } from 'antd';
import FormRender, { connectForm } from 'form-render';
import { Service } from '../../common/restful/Service';

type State = {
    recordData: any
};

class EditRecordModal extends React.Component<any, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            recordData: null,
        };
    };

    componentDidUpdate() {
        const { data, form } = this.props;
        const { recordData } = this.state;
        if (recordData !== data) {
            this.setState({ recordData: data });
            if (!!data) {
                form.setValues(data);
            }
        }
    }

    onFinish = (formData: any, errors: any) => {
        if (errors.length > 0) {
            return;
        }
        const { customTableDefinition } = this.props;
        const { recordData } = this.state;
        let data: any[] = [];
        if (!!formData) {
            for (const key of Object.keys(formData)) {
                if (key === 'recordId' || key === 'lastupdatedtime') {
                    continue;
                }
                let field = {
                    definitionId: customTableDefinition.id,
                    recordId: !recordData ? null : recordData.recordId,
                    fieldName: key,
                    fieldValue: formData[key],
                };
                data.push(field)
            }
        }
        this.saveRecord(data);
    };

    async saveRecord(data: any[]) {
        const { recordData } = this.state;
        try {
            let response: any = {};
            if (!recordData) {
                response = await Service.getInstance().createCustomRecord(data);
            } else {
                response = await Service.getInstance().updateCustomRecord(data);
            }
            if (response.data.code === 200) {
                message.success(!recordData ? '新增成功' : '修改成功');
                this.onModalClose(true);
            } else {
                message.error(!recordData ? '新增失败' : '修改失败');
            }
        } catch (e) {
            console.error(e);
            message.error(!recordData ? '新增失败' : '修改失败');
        }
    }

    onModalClose = (isRefresh: boolean) => {
        this.props.form.resetFields();
        if (!isRefresh) {
            this.setState({ recordData: null });
        }
        
        this.props.onEditFinish(false, isRefresh);
    }

    onModalCancel = () => {
        this.onModalClose(false);
    }

    render() {
        const { customTableDefinition, form } = this.props;
        const { recordData } = this.state;
        let formSchema: any = {};
        let tableDisplayName: string = '';
        if (!!customTableDefinition) {
            formSchema = JSON.parse(customTableDefinition.customizedFields);
            tableDisplayName = customTableDefinition.tableDisplayName;
        }

        return (
            <Modal
                visible={true}
                title={`${!recordData ? '新增' : '编辑'} - ${tableDisplayName}`}
                closable={false}
                maskClosable={false}
                destroyOnClose
                onOk={form.submit}
                onCancel={this.onModalCancel}
                width={'80%'}
                bodyStyle={{maxHeight: 500, overflow: 'hidden', overflowY: 'scroll'}}
            >
                <FormRender form={form} schema={formSchema} onFinish={this.onFinish} style={{top: 120}} />
            </Modal>
        )
    }
}

export const EditRecordModalWithConnectForm: any =  connectForm(EditRecordModal);
