import React from 'react';
import { Service } from '../common/restful/Service';
import { Row, Col, Form, Input, Button, Card, Empty, Spin, message, DatePicker } from 'antd';
import { ReportTable } from './components/reportTable';
import { convertDataRangeToString, convertStringToDataRange, history } from '../common/utils';

import '../styles/trace-report.css';

type Props = {};
type State = {
    responseData: any[],
    loading: boolean,
    customTableList: any,
    exporting: boolean,
};

export class Page extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            responseData: [],
            loading: false,
            customTableList: [],
            exporting: false,
        };
    };

    componentDidMount() {
        this.getCustomTableList()
        const locationState: any = history.location.state;
        if (!!locationState) {
            this.setState({ loading: true });
            this.getTraceReport(locationState)
        }
    }

    async getTraceReport(data: any) {
        let responseData: any = [];
        try {
            const response = await Service.getInstance().getTraceReport(data);
            responseData = response.data.data;
            if (response.data.code === 2201) {
                message.info(response.data.msg);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setState({
                loading: false,
                responseData: responseData
            });
        }
    };

    async getCustomTableList() {
        let customTableList: any = [];
        try {
            const response: any = await Service.getInstance().getCustomTableList();
            customTableList = response.data.data;
        } catch (e) {
            console.error(e);
        } finally {
            this.setState({
                customTableList,
            });
        }
    };

    onSearchFinish = (values: any) => {
        values.queryTime = convertDataRangeToString(values.queryTime);
        history.replace({ pathname: '/traceReport', state: values })
        this.setState({ loading: true });
        this.getTraceReport(values);
    };

    onExport = async () => {
        const { exporting } = this.state;
        if (exporting) {
            return;
        }
        this.setState({ exporting: true });
        const data = history.location.state;
        const response: boolean = await Service.getInstance().exportTraceReport(data);
        if (!response) {
            message.error('导出失败');
        }
        this.setState({ exporting: false });
    }

    renderSearchForm() {
        const locationState: any = history.location.state;
        const { RangePicker }: any = DatePicker;
        return (
            <Form
                className='form-container'
                onFinish={this.onSearchFinish}
                autoComplete="off"
            >
                <Row justify="start">
                    <Col span={8} style={{ paddingRight: 8 }}>
                        <Form.Item
                            label="钢线编号"
                            name="num"
                            initialValue={locationState?.num ?? ''}
                        >
                            <Input allowClear />
                        </Form.Item>
                    </Col>
                    
                </Row>
                <Row>
                    <Col span={8} style={{ paddingRight: 8 }}>
                        <Form.Item
                            label="时间"
                            name="queryTime"
                            initialValue={convertStringToDataRange(locationState?.queryTime) ?? ''}
                        >
                            <RangePicker />
                        </Form.Item>
                    </Col>
                    <Col span={6} style={{ paddingRight: 8 }}>
                        <Form.Item
                            label="砂批次号"
                            name="incomingBatch"
                            initialValue={locationState?.incomingBatch ?? ''}
                        >
                            <Input allowClear />
                        </Form.Item>
                    </Col>
                    <Col span={6} style={{ paddingRight: 8 }}>
                        <Form.Item
                            label="母线编号"
                            name="busIncomingNo"
                            initialValue={locationState?.busIncomingNo ?? ''}
                        >
                            <Input allowClear />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item>
                            <Button type="primary" className='action-button' htmlType="submit">查询</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    };

    renderOperation() {
        const { exporting, responseData } = this.state;
        const locationState: any = history.location.state;
        const hasFilterItem = locationState && Object.keys(locationState).filter(itemKey => locationState[itemKey] !== '').length > 0;
        const disabled = !hasFilterItem || !responseData || (responseData.length === 0);

        return (
            <div>
                <Row>
                    <Col span={4} offset={20} className='flex-end'>
                        <Button
                            type="primary"
                            className='action-button'
                            loading={exporting}
                            onClick={this.onExport}
                            disabled={disabled}
                        >
                            导出
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    };

    render() {
        const { responseData, loading, customTableList } = this.state;
        const locationState: any = history.location.state;

        return(
            <div className='trace-report-container'>
                <Card>
                    { this.renderSearchForm() }
                </Card>
                <Spin spinning={loading}>
                    <Card style={{marginTop: 20}}>
                        { this.renderOperation() }
                        { 
                            !!responseData && responseData.length > 0 ?
                            responseData.map((tableData, index) => (
                                <ReportTable data={tableData} searchNum={locationState?.num ?? ''} key={`${index}_${tableData.title}`} customTableList={customTableList} />
                            ))
                            :
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </Card>
                </Spin>
            </div>
        )
    }
}