import React from 'react';
import { Row, Col, Form, Input, message, Modal, Transfer } from 'antd';
import Generator from 'fr-generator';
import { FormInstance } from 'antd/es/form';
import { Service } from '../../common/restful/Service';
import '../../styles/custom-table.css';

type Props = {
    onEditFinish: (refresh: boolean) => void,
    data: any,
};
type State = {
    searchFieldsData: any[],
    targetKeys: string[],
    customizedFields: any,
};

const componentSettings: any = [{
    text: '输入框',
    name: 'input',
    schema: {
        title: '输入框',
        type: 'string',
    },
    setting: {
        props: {
            title: '选项',
            type: 'object',
            labelWidth: 80,
            properties: {
                allowClear: {
                    title: '是否带清除按钮',
                    description: '填写内容后才会出现x哦',
                    type: 'boolean',
                },
                addonBefore: {
                    title: '前tab',
                    type: 'string',
                },
                addonAfter: {
                    title: '后tab',
                    type: 'string',
                },
                prefix: {
                    title: '前缀',
                    type: 'string',
                },
                suffix: {
                    title: '后缀',
                    type: 'string',
                },
            },
        },
        minLength: {
            title: '最短字数',
            type: 'number',
        },
        maxLength: {
            title: '最长字数',
            type: 'number',
        },
        pattern: {
            title: '校验正则表达式',
            type: 'string',
            props: {
                placeholder: '填写正则表达式',
            },
        },
    },
}, {
    text: '日期选择',
    name: 'date',
    schema: {
        title: '日期选择',
        type: 'string',
        format: 'date',
    },
    setting: {
        format: {
        title: '格式',
        type: 'string',
        enum: ['dateTime', 'date', 'time'],
        enumNames: ['日期时间', '日期', '时间'],
        },
    },
}, {
    text: '时间选择',
    name: 'time',
    show: false,
    schema: {
        title: '时间选择',
        type: 'string',
        format: 'time',
    },
    setting: {
        format: {
        title: '格式',
        type: 'string',
        enum: ['dateTime', 'date', 'time'],
        enumNames: ['日期时间', '日期', '时间'],
        },
    },
}];

export class CustomTableModal extends React.Component<Props, State> {
    formRef = React.createRef<FormInstance>();
    genRef = React.createRef<any>()

    constructor(props: Props) {
        super(props);
        this.state = {
            searchFieldsData: [],
            targetKeys: [],
            customizedFields: null,
        };
    }

    componentDidMount() {
        const { data } = this.props;

        let selectFields: string[] = data?.selectFields ? data?.selectFields.split(',') : [];
        this.formRef.current?.setFieldsValue({
            tableNumber: data?.tableNumber,
            tableDisplayName: data?.tableDisplayName,
            tableCategory: data?.tableCategory,
            selectFields,
        });
        let schema = data?.customizedFields ? JSON.parse(data?.customizedFields) : ''
        this.setState({targetKeys: selectFields, customizedFields: schema});
        this.onSchemaChange(schema, true);
    }

    onFinish = async (values: any) => {
        const { data } = this.props;
        let selectFields: any = values['selectFields'];
        if (!!selectFields) {
            values['selectFields'] = selectFields.join(',');
        }
        try {
            let response: any = {};
            if (!data) {
                response = await Service.getInstance().createTableDefinition(values);
            } else {
                values['id'] = data?.id;
                response = await Service.getInstance().updateTableDefinition(values);
            }
            if (response.data.code === 200) {
                message.success(!data ? '新增成功' : '修改成功');
                this.closeDialog(true);
            } else {
                message.error(!data ? '新增失败' : '修改失败');
            }
        } catch (e) {
            console.error(e);
            message.error(!data ? '新增失败' : '修改失败');
        }
    };

    onSchemaChange = (schema: any, isInit?: boolean) => {
        const { targetKeys } = this.state;
        let schemaFields: any = [];
        let searchKeys: any = [];
        this.formRef.current?.setFieldsValue({customizedFields: JSON.stringify(schema)})
        if (!!schema) {
            schemaFields = this.getFields(schema.properties);
        }
        if (!isInit) {
            // 修改schema时检查选择的搜索条件是否被删除，若被删除则需要将搜索条件也一并删除
            let keys: any =  schemaFields.map((item: any) => {
                return item.key;
            });
            for (const targetKey of targetKeys) {
                if (keys.includes(targetKey)) {
                    searchKeys.push(targetKey)
                }
            }
            this.setState({targetKeys: searchKeys});
            this.formRef.current?.setFieldsValue({selectFields: searchKeys})
        }
        
        this.setState({searchFieldsData: schemaFields});
    }

    getFields = (properties: any) => {
        let fields: any[] = [];
        if (!properties) {
            return fields;
        }
        for (const key of Object.keys(properties)) {
            let property: any = properties[key];
            if (!!property && !!property.properties) {
                let result: any[] = this.getFields(property.properties);
                if (!!result && Object.keys(result).length > 0) {
                    fields.push(...result)
                    break
                }
                
            } else {
                let field = {
                    key,
                    title: property.title
                };
                fields.push(field);
            }
        }
        return fields;
    };

    handleChange = (nextTargetKeys: any) => {
        this.setState({ targetKeys: nextTargetKeys });
    };

    closeDialog = (refresh: boolean) => {
        this.formRef.current?.resetFields();
        this.props.onEditFinish(refresh);
    };

    render() {
        const { data } = this.props;
        const { searchFieldsData, targetKeys, customizedFields } = this.state;

        return (
            <Modal
                visible={true}
                title={ !data ? '新增自定义表' : '编辑自定义表'}
                centered
                closable={false}
                maskClosable={false}
                width={'90%'}
                bodyStyle={{maxHeight: 600, overflow: 'hidden', overflowY: 'scroll'}}
                onOk={() => { this.formRef.current?.submit() }}
                onCancel={() => { this.closeDialog(false) }}
            >
                <Form
                    ref={this.formRef}
                    className='form-container'
                    onFinish={this.onFinish}
                    autoComplete="off"
                    labelCol={{span: 4}}
                    wrapperCol={{span: 16}}
                    initialValues={data}
                >
                    <Row justify="start">
                        <Col span={8}>
                            <Form.Item
                                label="表编号"
                                name="tableNumber"
                                rules={[{ required: true, message: '表编号不可为空' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="表名称"
                                name="tableDisplayName"
                                rules={[{ required: true, message: '表名称不可为空' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="类型"
                                name="tableCategory"
                                rules={[{ required: true, message: '类型不可为空' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        label="自定义字段"
                        name="customizedFields"
                        labelAlign={'left'}
                        labelCol={{span: 0}}
                        wrapperCol={{span: 24}}
                        rules={[{ required: true, message: '自定义字段不可为空' }]}
                        className='flex-direction-column'
                    >
                        <div className='generator-item-container'>
                            <Generator
                                ref={this.genRef}
                                extraButtons={[ true, false, false, false ]}
                                settings={[
                                    {
                                        title: '基础组件',
                                        widgets: componentSettings,
                                        show: true,
                                        useCommon: true,
                                      },
                                ]}
                                defaultValue={customizedFields}
                                onSchemaChange={this.onSchemaChange}
                            />
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="查询字段"
                        name="selectFields"
                        labelCol={{span: 2}}
                        wrapperCol={{span: 22}}
                    >
                        <Transfer
                            dataSource={searchFieldsData}
                            titles={['自定义字段', '查询字段']}
                            targetKeys={targetKeys}
                            rowKey={record => record.key}
                            onChange={this.handleChange}
                            render={item => item.title}
                            oneWay
                            style={{ marginBottom: 16 }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}