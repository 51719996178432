import React from 'react';
import { Row, Col, Form, Input, Button, Card, Table, Popconfirm, message } from 'antd';
import { FormInstance } from 'antd/es/form';
import { CustomTableModal } from './components/customTableModal';
import { Service } from '../common/restful/Service';

type Props = {};
type State = {
    dataSource: any,
    showModal: boolean,
    currentRecord: any,
    loading: boolean,
};

export class Page extends React.Component<Props, State> {
    formRef = React.createRef<FormInstance>();

    columns: any[] = [{
        title: '序号',
        align: 'center',
        ellipsis: true,
        width: '7%',
        render: (text: any, record: any, index: number) => `${index + 1}`
    }, {
        title: '表编号',
        dataIndex: 'tableNumber',
        align: 'center',
        ellipsis: true,
        width: '10%',
    }, {
        title: '表名称',
        dataIndex: 'tableDisplayName',
        align: 'center',
        ellipsis: true,
    }, {
        title: '类型',
        dataIndex: 'tableCategory',
        align: 'center',
        ellipsis: true,
    }, {
        title: '自定义字段',
        dataIndex: 'titleHead',
        align: 'center',
        ellipsis: true,
    }, {
        title: '生成时间',
        dataIndex: 'createdTime',
        align: 'center',
    }, {
        title: '操作',
        align: 'center',
        width: 190,
        render: (record: any) => {
            return (
                <>
                    <Button type="primary" onClick={()=> {
                            this.showDetail(record)
                        }}
                    >
                        编辑
                    </Button>
                    <Popconfirm
                        title="确定要删除该表吗?"
                        onConfirm={()=> {
                            this.onConfirmDelete(record)
                        }}
                        okText="确定"
                        cancelText="取消"
                    >
                        <Button type="primary" style={{marginLeft: 5}}>删除</Button>
                    </Popconfirm>
                </>
            )
        }
    }];

    constructor(props: Props) {
        super(props)

        this.state = {
            dataSource: [],
            showModal: false,
            currentRecord: null,
            loading: false,
        };
    };

    componentDidMount() {
        this.getTableDefinitionList();
    }

    async getTableDefinitionList() {
        this.setState({ loading: true });
        let dataSource: any = [];
        let data: any = this.formRef.current?.getFieldsValue();
        try {
            const response: any = await Service.getInstance().getTableDefinitionList(data);
            dataSource = response.data.data;
        } catch (e) {
            console.error(e);
        } finally {
            this.setState({
                dataSource,
                loading: false,
            });
        }
    };

    onSearchFinish = (values: any) => {
       this.getTableDefinitionList()
    };

    onReset = () => {
        this.formRef.current?.resetFields();
        this.onRefresh();
    };

    onConfirmDelete = async (record: any) => {
        const { id } = record;
        try {
            const response: any = await Service.getInstance().deleteTableDefinition({id});
            if (response.data.code === 200) {
                message.success('删除成功');
                this.onRefresh();
            } else {
                message.error('删除失败');
            }
        } catch (e) {
            console.error(e);
            message.error('删除失败');
        }
    };

    showDetail = (record: any) => {
        this.setState({ showModal: true, currentRecord: record });
    };

    onEditFinish = (refresh: boolean) => {
        this.setState({ showModal: false, currentRecord: null });
        if (refresh) {
            this.onRefresh();
        }
    };

    onRefresh = () => {
        this.getTableDefinitionList();
    };

    renderSearchForm() {
        return (
            <Form
                ref={this.formRef}
                className='form-container'
                onFinish={this.onSearchFinish}
                autoComplete="off"
                labelCol={{span: 4}}
                wrapperCol={{span: 16}}
            >
                <Row justify="start">
                    <Col span={6}>
                        <Form.Item
                            label="表编号"
                            name="tableNumber"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="表名称"
                            name="tableDisplayName"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="类型"
                            name="tableCategory"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={2} offset={2} className='flex-end'>
                        <Form.Item>
                            <Button type="primary" className='action-button' htmlType="submit">查询</Button>
                        </Form.Item>
                    </Col>
                    <Col span={2} className='flex-end'>
                        <Form.Item>
                            <Button type="primary" className='action-button' onClick={this.onReset}>重置</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    };

    renderOperation() {
        return (
            <div>
                <Row>
                    <Col span={4} className='flex-start'>
                        <Button
                            type="primary"
                            className='action-button'
                            onClick={() => {
                                this.showDetail(null)
                            }}
                        >
                            新增
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    };

    render() {
        const { dataSource, showModal, currentRecord, loading } = this.state;

        return (
            <div style={{padding: 20}}>
                <h2>自定义表</h2>
                <Card>
                    { this.renderSearchForm() }
                </Card>
                <Card style={{marginTop: 10}}>
                    { this.renderOperation() }
                    <Table
                        style={{padding: 0, paddingTop: 10}}
                        dataSource={dataSource}
                        columns={this.columns}
                        loading={loading}
                        pagination={false}
                        bordered={true}
                        rowKey={(record) => record.id}
                        scroll={{y: 'calc(100vh - 350px)'}}
                    />
                </Card>
                {
                    showModal ?
                    <CustomTableModal
                        data={currentRecord}
                        onEditFinish={this.onEditFinish}
                    />
                    :
                    <></>
                }
            </div>
        )
    }
}