import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import 'moment/locale/zh-cn';
import App from './App';

ReactDOM.render((
  <ConfigProvider locale={zhCN}>
    <App />
  </ConfigProvider>
),document.getElementById('root'));
