import { Request } from './restful/Request';
import * as H from 'history';
import moment from 'moment';

const formatSingleColumn = (item: any) => {
    let column: any = null;

    if (!item.children || item.children.length === 0) {
        column = {
            key: item.fieldName,
            title: item.title,
            dataIndex: item.fieldName,
            align: 'center',
            ellipsis: true,
            width: 150,
        };
    } else {
        let children: any[] = [];
        item.children.forEach((child: any) => {
            let childItem = formatSingleColumn(child);
            children.push(childItem);
        });
        column = {
            title: item.title,
            children: children,
        };
    }
    return column;
};

const downLoadFile = async (url: string, method: string, data?: any) => {
    const a: any = document.createElement('a');
    try {
        const response: any = await Request({ url, method, responseType: 'blob', data });
        const fileData: any = response.data;
        const contentDisposition: string = response.headers['content-disposition'] ?? '';
        const fileName: string = !contentDisposition ? `${new Date().getTime()}.xlsx` : contentDisposition?.split('filename=')[1];
        a.download = decodeURI(fileName);
        a.href = window.URL.createObjectURL(fileData);
        a.click();
        return true;
    } catch (error) {
        window.console.error(error);
        return false;
    };
}

const convertDataRangeToString = (dateRange: any) => {
    if (!dateRange || !Array.isArray(dateRange)) return '';
    if (dateRange.length !== 2) return '';
    const startTime = moment(dateRange[0]).format('YYYY-MM-DD');
    const endTime = moment(dateRange[1]).format('YYYY-MM-DD');
    return `${startTime},${endTime}`;
}

const convertStringToDataRange = (dataRageStr: any) => {
    if (!dataRageStr) return '';
    const dateRange = dataRageStr.split(',');
    if (dateRange.length !== 2) return '';
    const startTime = moment(dateRange[0]);
    const endTime = moment(dateRange[1]);
    return [startTime, endTime];
}

const history: H.History<unknown> = H.createBrowserHistory();

export {
    formatSingleColumn,
    downLoadFile,
    convertDataRangeToString,
    convertStringToDataRange,
    history,
}