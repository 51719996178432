import React from 'react';
import { Button, message, Table } from 'antd';
import { formatSingleColumn, history } from '../../common/utils';

type Props = {
    data: any,
    searchNum: string,
    customTableList: any,
};
type State = {};

// 有操作列的表
const tablesWithAction: any = ['工单', '投料', '质量', '切割结果'];

export class ReportTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    formatColumns(tableColumns: any[]) {
        const { data } = this.props;
        let columns: any[] = [];
        tableColumns.forEach((item) => {
            let column = formatSingleColumn(item);
            if (!!column) {
                columns.push(column);
            }
        });
        if (tablesWithAction.includes(data.title)) {
            let isWorkOrderSheet: boolean = data.title === '工单';
            let actionColum = {
                key: 'action',
                title: '操作',
                align: 'center',
                fixed: 'right',
                width: isWorkOrderSheet ? 240 : 120,
                render: (record: any) => {
                    return (
                        isWorkOrderSheet ?
                        <>
                            <Button type="primary"
                                className='action-button'
                                size='small'
                                disabled={!record.productionLine}
                                onClick={() => {
                                    this.goAlarm(record);
                                }}
                            >
                                告警
                            </Button>
                            <Button type="primary"
                                style={{marginLeft: 5}}
                                size='small'
                                className='action-button'
                                disabled={!record.productionLine}
                                onClick={() => {
                                    this.goLineChart(record);
                                }}
                            >
                                采集数据
                            </Button>
                        </>
                        :
                        <Button type="primary"
                            size='small'
                            className='action-button'
                            onClick={() => {
                                this.showRecordDetail(record)
                            }}
                        >
                            查看
                        </Button>
                    )
                }
            };
            columns.push(actionColum);
        }
        return columns;
    };

    showRecordDetail = (record: any) => {
        const { data } = this.props;
        const searchNumColumn = data.columns.find((column: any) => column.title === '钢线编号');
        const searchNum = record[searchNumColumn.fieldName] || '';
        let params: any = {};
        switch (data.title) {
            case '投料':
                params = this.getSearchParamsByName(record);
                break;
            case '质量':
                let value: any =  {
                    A: searchNum,
                };
                if (record.type === '异常记录') {
                    value = {
                        E: searchNum,
                    };
                }
                if (record.type === '终检') {
                    value = {
                        G: searchNum,
                    };
                }
                params = {
                    id: this.getCustomIdByName(record.type),
                    value,
                };
                break;
            case '切割结果':
                params = {
                    id: this.getCustomIdByName('切割数据'),
                    value: {
                        A: searchNum,
                    },
                };
                break;
        }
        if (!params.id) {
            message.warning('未找到相关表单');
            return;
        }
        let path: any = { pathname: '/recordEntry', state: params };
        history.push(path);
    }

    goLineChart = (record: any) => {
        let params: any = {
            lineName: record?.productionLine,
            startTime: record?.startTime,
            endTime: record?.endTime,
        };
        let path: any = { pathname: '/lineChart', state: params };
        history.push(path);
    }

    goAlarm = (record: any) => {
        let params: any = {
            lineName: record?.productionLine,
            startTime: record?.startTime,
            endTime: record?.endTime,
        };
        let path: any = { pathname: '/alarm', state: params };
        history.push(path);
    }

    getCustomIdByName = (name: string) => {
        const { customTableList } = this.props;
        let id: string = customTableList.find((item: any) => item.tableDisplayName === name)?.id;
        return id;
    }

    // 获取投料表查看二级页面的跳转参数
    getSearchParamsByName = (record: any) => {
        let params: any = {
            id: '',
            value: null,
        };
        if (record.materialName === '母线') {
            params.id = this.getCustomIdByName('原材料-母线');
            params.value = {
                E: record.busIncomingNo,
            }
        } else if (record.materialName === '镀覆砂') {
            params.id = this.getCustomIdByName('原材料-镀覆砂');
            params.value = {
                D: record.incomingBatch,
            }
        }
        return params;
    }

    render() {
        const { data } = this.props;

        return (
            <div className='table-container'>
                <label className='record-title'>{data.title}</label>
                <Table
                    style={{padding: 0, paddingTop: 10}}
                    dataSource={data.data}
                    columns={this.formatColumns(data.columns)}
                    pagination={false}
                    bordered={true}
                    size='small'
                    rowKey={(record) => record.recordId || record.id}
                    scroll={{x: '100%', y: 'calc(100vh - 100px)'}}
                />
            </div>
        )
    }
}